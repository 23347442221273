/* .searchBox  .ant-select-dropdown {
  background-color: rgba(99, 76, 185, 0.54) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(5.7px) !important;
  -webkit-backdrop-filter: blur(5.7px) !important;
} */

/* .dropdownBoxSearch {
  color: #fff !important;
} */

.teste .rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner div {
  color: #fff !important;
}

.teste .rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner div:hover {
  color: #fff !important;
}

.teste .rc-virtual-list .rc-virtual-list-holder div .rc-virtual-list-holder-inner .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color:#634cb9 !important;
  color: #fff !important;
}
